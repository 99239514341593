import { Component, Input, OnInit } from '@angular/core';

import {
  UAH,
  WASTE_ACCOUNTANT_SIGN_CAPTION,
  WASTE_DIRECTOR_SIGN_CAPTION,
  WASTE_HEADER_NAME_CAPTION,
  WASTE_HEADER_NO_VAT_CAPTION,
  WASTE_HEADER_PRICE_CAPTION,
  WASTE_HEADER_QUANTITY_CAPTION,
  WASTE_HEADER_SUM_CAPTION,
  WASTE_NUMBER_CAPTION,
  WASTE_POA_CAPTION,
  WASTE_PROVIDER_NAME_CAPTION,
  WASTE_PROVIDER_SIGN_CAPTION,
  WASTE_SALE_PRICE_MODE_NOTE,
  WASTE_SELF_NAME_CAPTION,
  WASTE_SHOP_SIGN_CAPTION,
  WASTE_TOTAL_COST_SUM_CAPTION,
  WASTE_TOTAL_SALE_SUM_CAPTION,
  WASTE_TOTAL_SUM_CAPTION,
  WASTE_TOTAL_SUM_WITH_VAT_CAPTION,
  WASTE_TOTAL_VAT_CAPTION,
  WASTE_USER_NAME_CAPTION,
} from '../../receipt/receipt.const';
import { PrintWaste } from '../../receipt/types/print-waste.model';

@Component({
  selector: 'bk-view-waste',
  templateUrl: './view-waste.component.html',
  styleUrls: ['./view-waste.component.scss'],
})
export class ViewWasteComponent implements OnInit {
  @Input() waste: PrintWaste;

  numberCaption = WASTE_NUMBER_CAPTION;
  selfNameCaption = WASTE_SELF_NAME_CAPTION;
  providerNameCaption = WASTE_PROVIDER_NAME_CAPTION;
  userNameCaption = WASTE_USER_NAME_CAPTION;
  poaCaption = WASTE_POA_CAPTION;
  headerNameCaption = WASTE_HEADER_NAME_CAPTION;
  headerQuantityCaption = WASTE_HEADER_QUANTITY_CAPTION;
  headerNoVatCaption = WASTE_HEADER_NO_VAT_CAPTION;
  headerPriceCaption = WASTE_HEADER_PRICE_CAPTION;
  headerSumCaption = WASTE_HEADER_SUM_CAPTION;
  salePriceModeNote = WASTE_SALE_PRICE_MODE_NOTE;
  totalSumCaption = WASTE_TOTAL_SUM_CAPTION;
  totalCostSumCaption = WASTE_TOTAL_COST_SUM_CAPTION;
  totalSaleSumCaption = WASTE_TOTAL_SALE_SUM_CAPTION;
  totalVatCaption = WASTE_TOTAL_VAT_CAPTION;
  totalSumWithVatCaption = WASTE_TOTAL_SUM_WITH_VAT_CAPTION;
  directorSignCaption = WASTE_DIRECTOR_SIGN_CAPTION;
  accountantSignCaption = WASTE_ACCOUNTANT_SIGN_CAPTION;
  shopSignCaption = WASTE_SHOP_SIGN_CAPTION;
  providerSignCaption = WASTE_PROVIDER_SIGN_CAPTION;

  ngOnInit(): void {
    //
  }

  currency(value: string): string {
    return value ? `${value} ${UAH}` : '';
  }

  underLine(caption: string): string {
    if (caption === this.providerSignCaption) {
      return (
        this.waste.shop.includes('ФРМ') || this.waste.shop.includes('СКИБА')
          ? 'водій'
          : ''
      ).padEnd(this.waste.rowLength - caption.length - 2, '_');
    }

    return ''.padEnd(this.waste.rowLength - caption.length - 2, '_');
  }
}
